import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";

const videoConfig = [
  {
    name: "Welcome",
    urlHorizontal: "./video/w1.mp4",
    subtitles: "./video/welcome.vtt",
    buttons: [
      {
        text: "XReality Info",
        type: "video",
        name: "Xreality",
      },
      {
        text: "Euromersive Info",
        type: "video",
        name: "Euromersive",
      },
      {
        text: "Why to Join",
        type: "video",
        name: "Join",
      },
    ],
    notations: [],
  },
  {
    name: "Xreality",
    urlHorizontal: "./video/xr.mp4",
    subtitles: "./video/xr.vtt",
    buttons: [
      {
        text: "Euromersive Info",
        type: "video",
        name: "Euromersive",
      },
      {
        text: "Why to Join",
        type: "video",
        name: "Join",
      },
    ],
    notations: [],
  },
  {
    name: "Euromersive",
    urlHorizontal: "./video/eu.mp4",
    subtitles: "./video/eu.vtt",
    buttons: [
      {
        text: "XReality Info",
        type: "video",
        name: "Xreality",
      },
      {
        text: "Why to Join",
        type: "video",
        name: "Join",
      },
    ],
    notations: [],
  },
  {
    name: "Join",
    urlHorizontal: "./video/join.mp4",
    subtitles: "./video/joins.vtt",
    buttons: [
      {
        text: "What is XReality.gr",
        type: "video",
        name: "Xreality",
      },
      {
        text: "What is Euromersive",
        type: "video",
        name: "Euromersive",
      },
    ],
    notations: [],
  },
];

const JoinButton = ({
  text,
  onClick = () => {},
  className = "",
  color = "",
}) => {
  return (
    <button
      className={`btn btn-outline ${color}  ${className} hover:bg-transparent hover:border-2 hover:border-[#b505b2] hover:text-[#b505b2]`}
      onClick={() => {
        onClick(true);
      }}
      style={{ zIndex: 100 }}
    >
      {text}
    </button>
  );
};

const OptionButton = ({
  text,
  onClick = () => {},
  name = "",
  type = "video",
  component = null,
  className = "",
  color = "bg-[#6A2C91]",
}) => {
  const handleOnClick = () => {
    let objectToSend;
    if (type === "video") {
      const video = videoConfig.find((video) => video.name === name);
      if (video) {
        objectToSend = {
          type: type,
          goTo: video.name, // Send the name instead of the URL
        };
      } else {
        console.error("Video with specified name not found:", name);
        return; // Exit early if no matching video is found
      }
    } else {
      objectToSend = {
        type: type,
        goTo: component,
      };
    }
    onClick(objectToSend);
  };

  return (
    <button
      className={`btn btn-primary  ${color}  ${className}`}
      onClick={handleOnClick}
      style={{ zIndex: 100 }}
    >
      {text}
    </button>
  );
};

const Pannel = ({ onClick = () => {}, children, buttonText = "" }) => {
  const handleClose = () => {
    onClick(false);
  };

  return (
    <>
      <div
        id="pannel"
        className="w-8/12 h-screen-8 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-xl flex flex-col justify-center items-center gap-2 bg-gray-400 border-2 "
        style={{ zIndex: 200 }}
      >
        {children}

        <OptionButton
          text={"Sign to our news"}
          onClick={handleClose}
          className="absolute bottom-[10%] "
          color="btn-primary"
        />
      </div>
    </>
  );
};

function NewsletterSignup({ onClose = () => {}, onSuccess = () => {} }) {
  const [email, setEmail] = useState("");
  const [shoeAlert, setShowAlert] = useState({
    show: false,
    message: "",
    success: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    var formUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSeU6OJEdduH-n6s6EPrHGr7GE3egeaDmFJXBxo9ylFAjoClog/formResponse";
    var submitUrl = formUrl + "?entry.2040882842=" + encodeURIComponent(email);

    try {
      await fetch(submitUrl, {
        method: "GET",
        mode: "no-cors", // This mode allows the request to be made cross-origin but doesn't allow reading the response
      });
      setShowAlert({
        success: true,
        message: "Your email have been added to our List Sucesfully",
        show: true,
      });
      console.log("here");
      setTimeout(() => {
        setShowAlert({ show: false });
        onSuccess();
        onClose(false); // Close the form after submission
      }, 1000);
    } catch (error) {
      setShowAlert({
        success: false,
        message:
          "We had a problem and could not save your email! please try again a bit later!",
        show: true,
      });

      setTimeout(() => {
        setShowAlert({ show: false });
      }, 1000);
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className=" relative w-11/12 md:w-8/12 h-screen-8 rounded-lg flex flex-col justify-center items-center gap-1 md:gap-5 bg-white border-2 border-black"
        style={{ zIndex: 200 }}
      >
        <p className="font-main text-sm text-black w-3/4  md:2/4 text-center mb-5 text-[#b505b2] border-[#b505b2]">
          Stay ahead in the realm of immersive tech! Be the first to get
          updates, news, and launch notifications. Dive deep into Xreality with
          us.
        </p>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="input input-bordered input-primary w-full max-w-xs bg-gray-800 text-white"
          required
        />
        <button
          type="submit"
          className="btn btn-outline border-privary  text-primary hover:bg-[#b505b2] hover:text-white"
          style={{ zIndex: 200 }}
        >
          Subscribe
        </button>

        <button
          className="w-10 h-10 absolute top-[5%] right-[3%] rounded-full flex justify-center items-center bg-black"
          onClick={handleClose}
        >
          <p className="font-main">X</p>
        </button>

        <button className="btn btn-square btn-outline absolute bottom-[5%] right-[5%]">
          <a href="https://www.linkedin.com/groups/9338171/" target="_blank">
            <img src="./li.png" alt="linkedIn" />
          </a>
        </button>
      </form>

      {shoeAlert.show && (
        <Alert
          text={shoeAlert.message}
          className=" absolute bottom-10 left-0 "
          success={shoeAlert.show}
        />
      )}
    </>
  );
}

function Alert({ text = "", className = "", success = true }) {
  return (
    <div
      className={`alert ${
        success ? "alert-info" : "alert-error"
      } ${className} `}
      style={{ zIndex: 500 }}
    >
      {success && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-current shrink-0 w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      )}
      {!success && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="stroke-current shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )}
      <span className="font-main">{text}</span>
    </div>
  );
}

function InteractiveVideo({}) {
  const [currentVideo, setCurrentVideo] = useState(videoConfig[0]);
  const videoRefHor = useRef(null);
  const [start, setStart] = useState(false);
  const [videoKey, setVideoKey] = useState(0);
  const [showPannel, setShowPannel] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [pressedButtons, setPressedButtons] = useState(new Set());
  const [isRegistered, setIsRegistered] = useState(false);

  const handleStart = () => {
    setStart(true);

    const tl = gsap.timeline({
      onComplete: () => {
        gsap.to("#videoplayer", {
          opacity: 1,
          duration: 1,
        });
      },
    });

    tl.to("#StartButton", {
      opacity: 0,
      duration: 1,
      onComplete: showVideo,
    });
  };

  const showVideo = () => {
    console.log(videoRefHor.current); // Debugging statement
    const videoURL = currentVideo.urlHorizontal;
    const video = videoRefHor.current;
    if (video) {
      // Check if video is not null before attempting to access its properties
      video.src = videoURL;
      video.addEventListener("loadedmetadata", handleLoadedData); // Add event listener for loadedmetadata
    } else {
      console.error("Video ref is null");
    }
  };

  const handleVideoEnd = () => {
    setShowButtons(true);
  };

  const handleLoadedData = () => {
    const video = videoRefHor.current;
    if (video) {
      // Check if video is not null before attempting to access its properties
      video.play();
    } else {
      console.error("Video ref is null");
    }
  };

  const handleNextVideo = (objectToSend) => {
    if (objectToSend.type === "video") {
      const nextVideo = videoConfig.find(
        (video) => video.name === objectToSend.goTo
      );
      if (nextVideo) {
        setCurrentVideo(nextVideo);
        setShowButtons(false);
        showVideo(); // Trigger the showVideo function to play the next video
      } else {
        console.error(
          "Video with specified name not found:",
          objectToSend.goTo
        );
      }
    } else if (objectToSend.type === "component") {
      setShowPannel(true);
      setShowButtons(false);
    }

    // Add the button name to the pressedButtons set
    setPressedButtons((prevPressedButtons) => {
      const newPressedButtons = new Set(prevPressedButtons);
      newPressedButtons.add(objectToSend.goTo);
      return newPressedButtons;
    });
  };

  useEffect(() => {
    showVideo();
  }, [currentVideo]);

  return (
    <>
      <div className="video-container w-full h-full flex flex-col justify-center items-center gap-5">
        {/* Add style tag here */}
        <style>
          {`
    ::cue {
        font-family: 'Roboto Mono', monospace;
        font-size: 3vh;  /* Adjust the size as per your requirement */
        color: #6A2C91 !important;
        background-color: transparent !important;
        position: absolute !important;
        margin-bottom: 10%;  /* Added bottom margin */
    }

    video::cue-background {
        background-color: transparent !important;
    }
`}
        </style>

        {!start && (
          <h1 className="font-main text-primary text-center">
            Welcome to XReality.gr
          </h1>
        )}
        {!start && (
          <p className="font-art text-sm text-[#b505b2]">
            The Future of Immersive Technologies in Greece.
          </p>
        )}
        {!start && (
          <p className="font-art text-sm text-primary w-5/12">
            "Our community is currently in development, but we're excited to
            share our vision for the Greek Ecosystem through a short, interactive 
            video made with AI. Join us in exploring our vision and stay updated by
            subscribing to our newsletter. Enjoy the video, and be part of the
            journey!"
          </p>
        )}
        {!start && (
          <button
            id="StartButton"
            className="btn bg-[#b505b2]  font-main text-white"
            onClick={handleStart}
          >
            Start
          </button>
        )}

        {start && (
          <>
            <div className={`h-full w-full absolute top-0 left-0`}>
              <video
                key={currentVideo.name} // Change key to force re-render
                id="videoPlayerHor"
                ref={videoRefHor}
                onEnded={handleVideoEnd}
                onLoadedData={() => handleLoadedData("landscape")}
                controls={false}
                className="video h-full w-full relative top-0 left-0"
                style={{ objectFit: "cover" }}
              >
                {currentVideo.subtitles && (
                  <track
                    src={currentVideo.subtitles}
                    kind="subtitles"
                    srcLang="en"
                    label="English"
                    default
                  />
                )}
              </video>
            </div>

            {showButtons && (
              <div className="w-fit h-full absolute left-[20%] flex  flex-col justify-center items-center gap-4">
                {currentVideo.buttons
                  .filter((button) => !pressedButtons.has(button.name))
                  .map((button, index) => (
                    <OptionButton
                      key={index}
                      text={button.text}
                      type={button.type}
                      name={button.name} // Using name instead of url
                      component={button.component}
                      onClick={handleNextVideo}
                    />
                  ))}
              </div>
            )}

            {showPannel && (
              <NewsletterSignup
                onClose={(val) => {
                  setShowPannel(val);
                }}
                onSuccess={() => setIsRegistered(true)}
              />
            )}

            {!isRegistered && !showPannel && (
              <JoinButton
                className="absolute top-[10%] right-[5%] font-main text-bleck text-sm bg-grey-200 border-2 flex justify-center items-center text-primary"
                text="Join Us"
                onClick={() => {
                  console.log("ok");
                  setShowPannel(true);
                }}
              ></JoinButton>
            )}
            <p className="font-main text-[10px] text-primary absolute bottom-5 right-5">
              Slaps 2023
            </p>
          </>
        )}
      </div>
    </>
  );
}

export default InteractiveVideo;
