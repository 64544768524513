import { useRef, useEffect, useState } from "react";
import InteractiveVideo from "../Components/VideoAPp/InteractiveVideo";

const Section1 = () => {
  const emailInput = useRef();
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="hero h-screen-11 bg-tranparent">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-2xl font-bold font-art">Welcome to</h1>
          <h1 className="text-5xl font-bold font-art">xreality.gr</h1>
          <p className="py-6 font-main">
            The Immersive Technologies Greek Community
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-2 mt-10">
            <input
              ref={emailInput}
              type="text"
              placeholder="Email"
              className={`input  w-full max-w-xs bg-transparent text-primary font-main ${
                isActive ? "border-primary input-bordered" : "border-gray-200"
              }`}
              onFocus={() => setIsActive(true)}
              onBlur={() => setIsActive(false)}
            />
            <button className="btn btn-primary w-80 md:w-24 bg-primary ">
              Join Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <div
      id="Home"
      className="w-screen h-screen flex flex-col justify-start items-center bg-background  "
    >
      <InteractiveVideo />
    </div>
  );
};

export default Home;
