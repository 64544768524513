import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { state } from "./store";
import { useSnapshot } from "valtio";
import Home from "./Pages/Home";
import Join from "./Pages/Join";
import Euromersive from "./Pages/Euromersive";
import Contact from "./Pages/Contact";
import Header from "./Components/Header";

function RouteController() {
  const snapshot = useSnapshot(state); // Subscribe to Valtio state changes
  const navigate = useNavigate(); // Get the navigate function from React Router

  useEffect(() => {
    switch (snapshot.activeTab) {
      case "Home":
        navigate("/");
        break;
      case "Join":
        navigate("/join");
        break;
      case "Euromersive":
        navigate("/euromersive");
        break;
      case "Contact":
        navigate("/contact");
        break;
      default:
        console.error(`Unknown tab: ${snapshot.activeTab}`);
    }
  }, [snapshot.activeTab, navigate]);

  return null;
}

function App() {
  return (
    <>
      {/* Header */}
      {/* <Header /> */}
      <BrowserRouter>
        <RouteController />
        <div
          id="App"
          className="w-screen h-screen absolute left-0 flex justify-start items-center bg-background "
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/join" element={<Join />} />
            <Route path="/euromersive" element={<Euromersive />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </BrowserRouter>

      <div className="vertical-overlay">
        Please switch to landscape mode to use this app.
      </div>
    </>
  );
}

export default App;
